@use "@/sass/variables/colors.module";

.slateInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  margin-top: 20px;
  margin-bottom: 4px;
}

.titleLoading {
  width: 40%;
}

.genres {
  margin-top: 2px;
}

.genresLoading {
  width: 33%;
}

.description {
  margin-top: 18px;
}

.descriptionLoadingLastLine {
  width: 66%;
}
