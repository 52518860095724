@use "@/sass/abstracts/common";

.default {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &.includeHoverEffect {
    &:not(:disabled) {
      @include common.hover-reduce-opacity;
    }
  }
}
