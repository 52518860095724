@use "@/sass/abstracts/responsive-vw";
@use "@/sass/abstracts/fonts";
@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/common";
@use "@/sass/variables/fonts.module" as font-variables;

.heroStoryWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: center;
  width: 40%;
  min-height: 170px;
  border-radius: 32px;
}

.storyTitle {
  margin-bottom: 4px;
  color: colors.$color-grey-x-light;
}

.showTitleLink {
  margin-right: 12px;
  color: colors.$color-white;
  text-decoration: underline;

  @include common.hover-reduce-opacity;
}

.newBadge.newBadge {
  padding: 3px 8px;
}

.storyDescription {
  margin-top: 12px;

  @include responsive-vw.support-vw-tablets {
    @include fonts.font-size(120);
  }
}

.genres {
  margin-top: 16px;
}

.buttons {
  margin-top: 40px;
}
