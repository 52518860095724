@use "@/sass/abstracts/layout";
@use "@/sass/abstracts/common";
@use "@/sass/variables/content-padding.module";
@use "@/sass/abstracts/responsive-vw";

.scrollableRow {
  @include common.remove-scroll-bars;

  display: flex;
  padding-right: content-padding.$content-padding-left-right-mobile;
  padding-left: content-padding.$content-padding-left-right-mobile;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; /* Mobile safari */

  &:not(.disableScrollSnap) {
    scroll-snap-type: x mandatory;
    scroll-padding: 5%;
  }

  @include responsive-vw.support-vw-mobiles {
    padding-right: content-padding.$content-padding-left-right-small-mobile;
    padding-left: content-padding.$content-padding-left-right-small-mobile;
  }
}
