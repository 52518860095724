@use "@/sass/variables/colors.module";

.storiesWrapper {
  position: relative;
  width: 100%;
}

.titleSection.titleSection {
  align-items: flex-start;
}

.recommendationText {
  margin-top: 0;
  color: colors.$color-grey-x-light;
  opacity: 1;
  transition: opacity 0.2s linear;

  &.isLoading {
    opacity: 0;
  }
}
