@use "sass:color";
@use "@/sass/variables/colors.module";

$image-width: clamp(100px, 50vw, 225px);

.squareStoryImage {
  width: $image-width;
  height: $image-width;
  padding-bottom: unset !important;
  border-radius: 8px;
  transform: translateZ(0); // Make sure blur works with border radius on safari
  filter: drop-shadow(0 4px 4px color.scale(colors.$color-black, $alpha: -50%));
  transition: border 0.3s linear;
  aspect-ratio: 1;
}

.actionButtonsWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}
