@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/abstracts/fonts";

@mixin support-buttons-small-mobiles {
  @media only screen
    and  (max-width: 440px) { @content; }
}

.actionButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  margin-top: 24px;

  .button-component,
  .button-component-loading {
    margin-right: 16px;
  }
}

.infoButton {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.infoIcon {
  position: relative;
  bottom: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  margin-left: 40px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.playPauseButton {
  p {
    @include responsive-vw.support-vw-small-mobiles {
      @include fonts.font-size(50);
    }
  }
}
