@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/responsive-vw";

.tipWrapper {
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 40px;
  }
}

.tipIcon {
  --svg-color: #{colors.$color-white};

  display: flex;
  margin-top: auto;
  margin-right: 24px;
  margin-bottom: auto;

  svg {
    width: 52px;
    height: 52px;
  }
}

.tipTextWrapper {
  display: flex;
  flex-direction: column;
  margin: auto 0;
}

.header {
  color: colors.$color-white;

  @include responsive-vw.support-vw-small-mobiles {
    @include fonts.font-size(60);
  }
}

.description {
  color: colors.$color-grey-x-light;

  @include responsive-vw.support-vw-small-mobiles {
    @include fonts.font-size(50);
  }
}
