@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/responsive-vw";
@use "../styles.module.scss";

.heroShiftedPageContent {
  position: absolute;
  top: styles.$hero-content-vertical-shift;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.showContentWrapper {
  @include responsive-vw.only-display-for-non-mobile-widths;
}
