@use "@/sass/variables/layout-dimensions.module";
@use "@/sass/abstracts/responsive-vw";
@use "../styles.module.scss";

.heroSectionWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height:
    calc(
      styles.$hero-content-vertical-shift
    );
}

.heroSectionInfo {
  position: relative;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - styles.$header-height-with-padding-adjustment);

  &.bottomAligned {
    align-items: flex-end;
    margin-bottom: clamp(64px, 5vw, 128px);
  }
}

.internalWrapper {
  position: relative;
  justify-content: flex-start;
}

.showTopBanner {
  position: absolute;
  z-index: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.showHeroImageWrapper {
  position: relative;
  bottom: styles.$hero-image-bottom-adjustment;
  align-self: flex-end;
  width: styles.$hero-image-width;
  min-width: styles.$hero-image-min-width;
  max-width: styles.$hero-image-max-width;

  @media only screen and  (max-width: 1500px) {
    right: styles.$hero-image-tablet-right-adjustment;
  }

  @include responsive-vw.support-vw-tablets {
    bottom: styles.$hero-image-tablet-bottom-adjustment;
  }
}

.showHeroImageLeftGradientWrapper {
  @include responsive-vw.support-vw-tablets {
    width: calc(100% - (styles.$hero-image-tablet-right-adjustment)) !important;
  }
}

.showTopBannerImage {
  @include responsive-vw.only-display-for-non-mobile-widths;

  overflow: hidden;
}
