@use "@/sass/abstracts/common";
@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/loading-states";

.badge {
  display: flex;
  align-items: center;
  width: max-content;
  height: max-content;
  padding: 2px 8px;
  color: colors.$color-white;
  background: colors.$color-grey;
  border-radius: 8px;
  box-shadow: 1px 1px 10px -4px black;
  cursor: auto;

  &.isClickable {
    &:not(.isLoading) {
      @include common.hover-reduce-opacity;

      cursor: pointer;
    }
  }

  &.isLoading {
    min-width: 100px;
    background: unset;

    @include loading-states.loading-gradient-background-horizontal("loading");
  }
}

.largeSize {
  padding: 6px 12px;
}

.badgeText {
  position: relative;
  white-space: pre-wrap;
  word-wrap: break-word;

  &.disableTextWrap {
    white-space: unset;
    word-wrap: unset;
  }
}
