@use "@/sass/abstracts/responsive-vw";
@use "@/sass/variables/colors.module";

.overlayCommon {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;

  &.isOpen {
    pointer-events: auto;
  }
}

.overlayBackground {
  background: colors.$color-black;

  &.isOpen {
    opacity: 0.4;

    @include responsive-vw.support-vw-mobiles {
      opacity: 0.4;
    }
  }

  &.isUnderAnotherModal {
    opacity: 0;
  }
}

.overlayContent {
  &.isOpen {
    opacity: 1;
  }

  &.isUnderAnotherModal {
    opacity: 0;
  }
}
