@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/abstracts/fonts";
@use "@/sass/variables/fonts.module" as font-variables;

.onboardingModalContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-width: 880px;
  font-family: font-variables.$font-stack-roc;

  @include responsive-vw.support-vw-mobiles {
    max-width: 85%;
  }
}

.title {
  align-self: center;
  margin: 0 64px;
  margin-bottom: 54px;
  text-align: center;

  @include responsive-vw.support-vw-mobiles {
    @include fonts.font-size(120);

    margin: 0 16px;
    margin-bottom: 48px;
  }

  @include responsive-vw.support-vw-small-mobiles {
    @include fonts.font-size(100);
  }
}

.tipSection {
  max-width: 640px;
}

.startListeningButton {
  align-self: center;
  max-width: 200px;
  margin-top: 54px;

  @include responsive-vw.support-vw-mobiles {
    margin-top: 64px;
  }

  &.blue {
    color: colors.$color-white;
    background: colors.$color-blue;
  }
}

.handpickedTip {
  svg {
    position: relative;
    left: 3px;
    transform: scale(1.1);
  }
}
