@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/common";
@use "@/sass/abstracts/responsive-vw";

$blur-negative-margin: 20px;

.tile {
  position: relative;
  flex-shrink: 0;
  width: clamp(240px, 76vw, 380px);
  margin-top: 12px;
  overflow: hidden;
  border-radius: 20px;
  transform: translateZ(0); // Make sure blur works with border radius on safari
  cursor: pointer;
  transition: border 0.3s linear;
  scroll-snap-align: start;

  @include common.hover-reduce-opacity(0.9);

  &:not(:last-child) {
    margin-right: 16px;
  }

  &.isLoading {
    border: transparent;
    cursor: auto;
  }

  &.hasBeenPressed {
    transform: scale(0.95);
    transition: transform 0.15s ease-out;
  }
}

.tileContent {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
  padding-bottom: 24px;
}

.tileBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + ($blur-negative-margin * 2));
  height: calc(100% + ($blur-negative-margin * 2));
  margin: -$blur-negative-margin;
  border-radius: inherit;
  filter: blur(12px) brightness(0.65);

  img {
    min-height: 100%;
  }
}
