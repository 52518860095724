@use "@/sass/abstracts/common";
@use "@/sass/variables/colors.module";

.badgeList {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;

  &.isVerticalltyCentered {
    position: relative;
    top: -5px;
  }
}

.badgeWrapper {
  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}
