@use "@/sass/variables/layout-dimensions.module";

$hero-image-aspect-ratio: 8 / 5;
$hero-image-width: 100%;
$hero-image-min-width: max(65vw, calc(800px * $hero-image-aspect-ratio));
$hero-image-max-width: 100%;
$hero-content-vertical-shift: clamp(500px, 65vh, 35vw);

/* Hero adjustments */
$hero-image-bottom-adjustment: 5vw;

/* Tablet hero adjustments */
$hero-image-tablet-right-adjustment: calc(-250px + 14vw);
$hero-image-tablet-bottom-adjustment: calc(85px - 2.5vw);

/* Header height without bottom padding */
$header-height-with-padding-adjustment: calc(
  layout-dimensions.$header-height-px-desktop
  - (layout-dimensions.$header-top-bottom-padding-desktop / 2)
);
