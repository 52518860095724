@use "@/sass/abstracts/responsive-vw";

.carouselIndicatorsAndHeroWrapper {
  position: relative;
}

.carouselIndicatorsWrapper {
  position: absolute;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: clamp(500px, 65vh, 35vw);
}

.carouselIndicatorsAdjusted {
  position: relative;
  bottom: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
}

.heroSlider {
  position: relative;
  transition: left 1s ease-in-out;
}

.customIndicator.customIndicator {
  width: 100px;
  height: 100px;
  border-radius: 16px;

  @include responsive-vw.support-vw-tablets {
    width: 80px;
    height: 80px;
  }
}
