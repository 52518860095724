@use "@/sass/variables/colors.module";
@use "@/sass/abstracts/common";

.carouselIndicators {
  display: flex;
}

.carouselIndicator {
  width: 16px;
  height: 16px;
  overflow: hidden;
  background: colors.$color-white;
  border-radius: 50%;
  opacity: 0.4;
  transition: opacity 0.5s ease-in-out;

  &.isSelected {
    border: 1px solid colors.$color-grey-x-light;
    opacity: 1;
    filter: contrast(125%);
  }

  &:not(.isSelected) {
    @include common.hover-reduce-opacity;

    cursor: pointer;
    transition: opacity 0.5s ease-in-out;
  }

  &:not(:last-child) {
    margin-right: 40px;
  }
}
